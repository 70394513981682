import MinterComponent from '../../components/minter/minter.component'
import './store.view.scss'
import Footer from '../../components/Footer/Footer.component'
import HeroStore from '../../components/Hero/assets/actual/awaken/store.jpg'

export default function StoreView() {
  return (
    <div className="store-view">
      <div className='store-view__title'>
        <h2>OFFICIAL BOOSTER PACKS STORE</h2>
        <div className='store-view__subtitle'>
          <div>Want to buy using DEV</div>
          <img src='/images/currency/XDEV.png' />
          <div>token? follow</div>
          <div>
            <a style={{ color: 'orange', fontFamily: 'rajdhani' }} target='_blank'
              href="https://market.immutable.com/collections/0xb93a4e2c02ed1486351e90cf52de8d51a67aaaf6?filters%5BAssetType%5D%5B%5D=Booster&filters%5BBoosterType%5D%5B%5D=STANDARD_EC1&filters%5BBoosterType%5D%5B%5D=DOUBLE_EC1&filters%5BBoosterType%5D%5B%5D=STANDARD_EC2&filters%5BBoosterType%5D%5B%5D=DOUBLE_EC2&filters%5BBoosterType%5D%5B%5D=DIAMOND_BOOSTER&filters%5BBoosterType%5D%5B%5D=GOLD_BOOSTER&filters%5BBoosterType%5D%5B%5D=SILVER_BOOSTER&filters%5BBoosterType%5D%5B%5D=GOLD&filters%5BBoosterType%5D%5B%5D=SILVER&filters%5BBoosterType%5D%5B%5D=DIAMOND&currencyFilter=0xa3d59c6d24f428dcfedc09724869e7af4d281fdd" rel="noreferrer">
              this link
            </a>
          </div>
        </div>
      </div >

      <div className="minter-component">
        <MinterComponent />
      </div>
      <div className='store-view__footer'> </div>
      <div className='store-banner-centered'>
        <a target='_blank' rel="noreferrer" href='https://docs.deviantsfactions.com/promo-sale-odds/promo-sale-1'>
          <img src={HeroStore} className='img-banner-size' alt="store" />
        </a>
      </div>
      <Footer />
    </div >
  )
}
