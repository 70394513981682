import { Config, ImmutableXConfiguration } from '@imtbl/core-sdk'

const stage = process.env.REACT_APP_STAGE || 'prod'

type ContractAddress = {
  [network: string]: string
}

type config = {
  stage: string,
  devFeatures: boolean,
  showPlayButton: boolean,
  minter: Function,
  collectionContract: Function,
  allCards: boolean,
  enableSigning: boolean,
  imageUrl: string,
  bridgeAddress: string,
  ENABLE_DECK_BUILDER: boolean,
  ENABLE_OPENER: boolean,
  hostURL: string,
  ENABLE_MY_CARDS: boolean,
  ENABLE_MY_SALES: boolean,
  ENABLE_MY_BOOSTERS: boolean,
  ENABLE_STORE: boolean,
  ENABLE_TOURNAMENTS: boolean,
  ENABLE_PURCHASE: boolean,
  ENABLE_BRIDGE: boolean,
  ENABLE_REFERRAL: boolean,
  ENABLE_BALANCE: boolean,
  ENABLE_REWARDS: boolean,
  ENABLE_TICKETS: boolean,
  ENABLE_CRAFTING: boolean,
  ENABLE_PROFILE: boolean,
  ENABLE_DECK_FORMATS: boolean,
  ENABLE_VOL_EXCHANGE: boolean,
  ENABLE_DEV_EXCHANGE: boolean,
  ENABLE_DECK_LOCK: boolean,
  ENABLE_NFT_CHECKING: boolean,
  ENABLE_ACHIEVEMENTS: boolean,
  ENABLE_CHALLENGES: boolean,
  ENABLE_ECHOES: boolean,
  LINK_ADDRESS: string,
  CURRENT_LEAGUE: string,
  SALES_RECIPIENT_ADDRESS: string,
  EXCHANGE_ADDRESS: string,
  USDC_TOKEN_ADDRESS: string,
  DEV_TOKEN_ADDRESS: string,
  IMX_TOKEN_ADDRESS: string,
  ETH_TOKEN_ADDRESS: string,
  GODS_TOKEN_ADDRESS: string,
  MAX_LIMIT_PURCHASES: number,
  API_ADDRESS: string,
  tutorials: boolean
  WALLET_TREASURY_ADDRESS: string
  IMX_CONFIG: ImmutableXConfiguration
  ETH_NETWORK: string
  CARD_SPRITE: boolean
  CARD_COUNT: number
  AIRDROP_MIN_XP: number
}

const MINTER: ContractAddress = {
  classic: 'terra1jwpmyayarr22p20w3vzlee0q3092w2av6v8c68',
  testnet: 'terra10lf6uvf6pn0fsete9fugmend2nsr3xuava7hz9',
}

const collectionContract = new Map<string, string>([
  ['local', '0xf06155bb7210c93bb6d9052d254aafccc8121c06'],
  ['zacatecas', '0xf06155bb7210c93bb6d9052d254aafccc8121c06'],
  ['staging', '0xd8565883eE4405aaE114CFddE90255a6526eA427'],
  ['prod', '0xb93A4E2C02Ed1486351E90cf52de8d51A67aaaf6'],
])

const COLLECTION_ADDRESS: ContractAddress = {
  goerli: collectionContract.get(stage) || '',
  sandbox: collectionContract.get(stage) || '',
  mainnet: '0xb93A4E2C02Ed1486351E90cf52de8d51A67aaaf6',
}

const getMinter = (network: string): string => MINTER[network]
const getCollectionContract = (network: string): string => COLLECTION_ADDRESS[network]

const base: config = {
  stage: 'prod',
  devFeatures: false,
  showPlayButton: true,
  minter: getMinter,
  collectionContract: getCollectionContract,
  allCards: true,
  enableSigning: true,
  imageUrl: 'https://devstatic.deviantsfactions.com/',
  bridgeAddress: 'terra1w22ft4er6jtrm0elg0v3xh94qgezysc32m5jtp',
  ENABLE_DECK_BUILDER: true,
  ENABLE_OPENER: true,
  hostURL: process.env.REACT_APP_HOST || 'https://deviantsfactions.com',
  ENABLE_MY_CARDS: true,
  ENABLE_MY_SALES: true,
  ENABLE_ECHOES: false,
  ENABLE_DEV_EXCHANGE: false,
  ENABLE_MY_BOOSTERS: true,
  ENABLE_STORE: true,
  ENABLE_TOURNAMENTS: true,
  ENABLE_PURCHASE: true,
  ENABLE_BRIDGE: true,
  ENABLE_REFERRAL: true,
  ENABLE_BALANCE: true,
  ENABLE_REWARDS: true,
  ENABLE_TICKETS: true,
  ENABLE_CRAFTING: true,
  ENABLE_PROFILE: true,
  ENABLE_CHALLENGES: false,
  ENABLE_VOL_EXCHANGE: true,
  ENABLE_DECK_LOCK: true,
  ENABLE_DECK_FORMATS: false,
  ENABLE_ACHIEVEMENTS: true,
  ENABLE_NFT_CHECKING: true,
  CURRENT_LEAGUE: 'deviants-league-11',
  DEV_TOKEN_ADDRESS: '0xa3d59c6d24f428dcfedc09724869e7af4d281fdd',
  USDC_TOKEN_ADDRESS: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  IMX_TOKEN_ADDRESS: '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
  ETH_TOKEN_ADDRESS: '0x1b44F3514812d835EB1BDB0acB33d3fA3351Ee43',
  GODS_TOKEN_ADDRESS: '0xccc8cb5229b0ac8069c51fd58367fd1e622afd97',
  LINK_ADDRESS: 'https://link.sandbox.x.immutable.com',
  SALES_RECIPIENT_ADDRESS: '',
  EXCHANGE_ADDRESS: '0x07d64ea440156a58e2af66d513908aafe6f85f59',
  MAX_LIMIT_PURCHASES: 5,
  API_ADDRESS: 'https://api.sandbox.x.immutable.com',
  tutorials: true,
  WALLET_TREASURY_ADDRESS: '0x833e88de3598bc0476a54ed92ab744f6b2731e52',
  IMX_CONFIG: Config.SANDBOX,
  ETH_NETWORK: 'sandbox',
  CARD_SPRITE: true,
  CARD_COUNT: 220,
  AIRDROP_MIN_XP: 4500,
}

const prod: config = {
  ...base,
  stage: 'prod',
  allCards: true,
  enableSigning: true,
  showPlayButton: true,
  ENABLE_BRIDGE: true,
  ENABLE_DEV_EXCHANGE: true,
  ENABLE_STORE: true,
  ENABLE_MY_BOOSTERS: true,
  ENABLE_TOURNAMENTS: true,
  ENABLE_REWARDS: true,
  ENABLE_PROFILE: true,
  ENABLE_CHALLENGES: true,
  ENABLE_DECK_FORMATS: true,
  ENABLE_VOL_EXCHANGE: true,
  ENABLE_ECHOES: true,
  ENABLE_ACHIEVEMENTS: true,
  DEV_TOKEN_ADDRESS: '0xa3d59c6d24f428dcfedc09724869e7af4d281fdd',
  hostURL: process.env.REACT_APP_HOST || 'https://deviantsfactions.com',
  LINK_ADDRESS: 'https://link.x.immutable.com',
  SALES_RECIPIENT_ADDRESS: '0xfb7D7c6c26eFFf28F7C628A610D6f09bF3392740',
  USDC_TOKEN_ADDRESS: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  API_ADDRESS: 'https://api.x.immutable.com',
  tutorials: true,
  IMX_CONFIG: Config.PRODUCTION,
  ETH_NETWORK: 'mainnet',
}

const staging: config = {
  ...base,
  stage: 'staging',
  allCards: true,
  enableSigning: true,
  showPlayButton: true,
  ENABLE_BRIDGE: true,
  ENABLE_PURCHASE: true,
  ENABLE_CHALLENGES: true,
  ENABLE_STORE: true,
  ENABLE_ECHOES: true,
  ENABLE_MY_BOOSTERS: true,
  ENABLE_NFT_CHECKING: false,
  ENABLE_BALANCE: true,
  ENABLE_REWARDS: true,
  ENABLE_PROFILE: true,
  ENABLE_DECK_FORMATS: true,
  ENABLE_TOURNAMENTS: true,
  ENABLE_VOL_EXCHANGE: true,
  USDC_TOKEN_ADDRESS: '0xca6c31cc71fb8f00dc2c465299f605039e0e1f4b',
  WALLET_TREASURY_ADDRESS: '0x445a8926d408aa0c0c1746c997c0b611e914025c',
  SALES_RECIPIENT_ADDRESS: '0x518eDF933c4b44413f5224E6598A23cF83347693',
  tutorials: true,
  hostURL: process.env.REACT_APP_HOST || 'https://staging.deviantsfactions.com',
  CARD_SPRITE: true,
}

const zacatecas: config = {
  ...base,
  stage: 'zacatecas',
  allCards: true,
  enableSigning: true,
  showPlayButton: true,
  ENABLE_BRIDGE: true,
  ENABLE_PURCHASE: true,
  ENABLE_ECHOES: true,
  ENABLE_STORE: true,
  ENABLE_TOURNAMENTS: true,
  ENABLE_DEV_EXCHANGE: true,
  ENABLE_MY_BOOSTERS: true,
  ENABLE_BALANCE: true,
  ENABLE_TICKETS: true,
  ENABLE_REWARDS: true,
  ENABLE_PROFILE: true,
  ENABLE_CHALLENGES: true,
  ENABLE_DECK_FORMATS: true,
  ENABLE_VOL_EXCHANGE: true,
  USDC_TOKEN_ADDRESS: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  WALLET_TREASURY_ADDRESS: '0x68e5178C468Cb2f0834AEA84032A18100e16a685',
  SALES_RECIPIENT_ADDRESS: '0x518eDF933c4b44413f5224E6598A23cF83347693',
  tutorials: true,
  hostURL: process.env.REACT_APP_HOST || 'https://zacatecas.deviantsfactions.com',
  CARD_SPRITE: true,
}

const local: config = {
  ...base,
  stage: 'local',
  ENABLE_CHALLENGES: true,
  hostURL: process.env.REACT_APP_HOST || 'http://localhost:9320',
  tutorials: true,
  CARD_SPRITE: true,
}

const getConfig = (): config => {
  switch (stage) {
    case 'prod': return prod
    case 'staging': return staging
    case 'zacatecas': return zacatecas
    case 'local': return local
    default: return prod
  }
}

export default getConfig
