import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useEffect, useState } from 'react'
import './LeagueModal.scss'
import Button from 'components/Button/Button.component'
import Checkbox from 'components/Checkbox/Checkbox.component'

export default function LeagueModal() {
  const [modalState, setModalState] = useState(false)
  const [showModalCheck, setShowModalCheck] = useState(false)

  const onClose = () => setModalState(false)
  useEffect(() => {
    const userSetting = localStorage.getItem('showLeagueModal')
    if (userSetting !== undefined && userSetting !== null) {
      setModalState(userSetting === '1')
    } else {
      setModalState(true)
    }
  }, [])

  const setModalStateAndSave = (state: boolean) => {
    localStorage.setItem('showLeagueModal', state ? '0' : '1')
  }

  const handleCheckboxChange = () => {
    setShowModalCheck(!showModalCheck)
    setModalStateAndSave(!showModalCheck)
  }

  return (
    <Modal isActive={modalState} closeAction={onClose} id='league-modal'>
      <div id='league-modal__tools'>
        <div className='close btn btn-small btn-primary' onClick={() => setModalState(false)}>&#x2715;</div>
      </div>
      <img src='/images/popup-l11.gif' alt='league modal' height={'400px'} />
      <div id='league-modal__buttons'>
        <a href='/tutorials'>
          <Button variant='primary' size='medium'>{'Learn more'}</Button>
        </a>
      </div>
      <Checkbox onClick={() => handleCheckboxChange()} text="Do not show this again" checked={false} disabled={false} />
    </Modal>)
}
